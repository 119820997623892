<template>
  <v-dialog
    v-model="open"
    scrollable
    persistent
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="color3" text v-on="on" :class="btnClass" :x-small="xSmall">Manual Finish</v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Manually Finish Pool</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-data-table
          :headers="headers"
          :items="teams"
          hide-default-footer
          class="elevation-1"
          :mobile-breakpoint="0"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td class="text-center border-right pb-1 px-4" width="75px">
                  <v-text-field
                    hide-details
                    v-model="item.finish"
                    type="number"
                    single-line
                  ></v-text-field>
                </td>
                <td class="text-left" :class="{'border-right': !$vuetify.breakpoint.xsOnly}">
                  <i :class="'fas fa-circle '  + teamColors[item.slot - 1]"></i> {{item.name}}
                </td>
                <template v-if="!$vuetify.breakpoint.xsOnly">
                  <td class="text-center border-right pb-1 px-4" width="75px">
                    <v-text-field
                      hide-details
                      v-model="item.matchWins"
                      type="number"
                      single-line
                    ></v-text-field>
                  </td>
                  <td class="text-center border-right pb-1 px-4" width="75px">
                    <v-text-field
                      hide-details
                      v-model="item.matchLosses"
                      type="number"
                      single-line
                    ></v-text-field>
                  </td>
                  <td class="text-center border-right pb-1 px-4" width="75px">
                    <v-text-field
                      hide-details
                      v-model="item.pointsFor"
                      type="number"
                      single-line
                    ></v-text-field>
                  </td>
                  <td class="text-center pb-1 px-4" width="75px">
                    <v-text-field
                      hide-details
                      v-model="item.pointsAgainst"
                      type="number"
                      single-line
                    ></v-text-field>
                  </td>
                </template>
                </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-row dense>
          <v-col cols="12">
            <v-alert type="warning" :value="warn">
              Manually changing pool finishes with tie breaks will remove the tie break explanation.
            </v-alert>
          </v-col>
        </v-row>
        <v-row dense justify="end">
          <v-btn color="success" class="mr-3" text v-if="user && user.vbl" @click.stop="rando">Rando</v-btn>
          <v-fab-transition>
            <v-btn
              color="success whiteText--text"
              class="mr-1"
              :key="`sav-${valid}`"
              fab small
              :disabled="!valid"
              :loading="loading"
              @click.stop="save"
            >
              <v-icon small>fas fa-save</v-icon>
            </v-btn>
          </v-fab-transition>
          <v-btn
            fab small color="error white--text"
            @click.stop="open = false"
          >
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['pool', 'teamColors', 'btnClass', 'x-small'],
  data () {
    return {
      open: false,
      loading: false,
      teams: []
    }
  },
  computed: {
    ...mapGetters(['user']),
    rankedTeams () {
      if (!this.pool) return []
      return this.pool.rankedTeams
    },
    newHeaders () {
      const m = this.pool.setting.isMatch
      const a = true
      const d = this.pool.isDuals
      return [
        { text: 'Finish', align: 'center', sortable: false, value: 'finish', show: true },
        { text: 'Team Name', align: 'left', sortable: false, value: 'name', show: true },
        { text: 'Wins', align: 'center', sortable: false, value: 'wins', show: !a },
        { text: 'Losses', align: 'center', sortable: false, value: 'losses', show: !a },
        { text: 'Duals', align: 'center', sortable: false, value: 'duals', show: a && d },
        { text: 'Matches', align: 'center', sortable: false, value: 'matches', show: a && m },
        { text: 'Sets', align: 'center', sortable: false, value: 'sets', show: a && m },
        { text: 'PF', align: 'center', sortable: false, value: 'pointsFor', show: a },
        { text: 'PA', align: 'center', sortable: false, value: 'pointsAgainst', show: a },
        { text: 'PD', align: 'center', sortable: false, value: 'pointDiff', show: a }
      ].filter(f => f.show)
    },
    headers () {
      if (!this.pool) return []
      if (this.$vuetify.breakpoint.xsOnly) {
        return [
          { text: 'Finish', align: 'left', sortable: false },
          { text: 'Team Name', align: 'left', sortable: false }
        ]
      }
      if (this.pool.setting.isMatch) {
        return [
          { text: 'Finish', align: 'left', sortable: false },
          { text: 'Team Name', align: 'left', sortable: false },
          { text: 'Matches', align: 'center', sortable: false },
          { text: 'Sets', align: 'center', sortable: false },
          { text: 'PF', align: 'center', sortable: false },
          { text: 'PA', align: 'center', sortable: false }
        ]
      }
      return [
        { text: 'Finish', align: 'left', sortable: false },
        { text: 'Team Name', align: 'left', sortable: false },
        { text: 'Wins', align: 'center', sortable: false },
        { text: 'Losses', align: 'center', sortable: false },
        { text: 'PF', align: 'center', sortable: false },
        { text: 'PA', align: 'center', sortable: false }
      ]
    },
    valid () {
      if (this.user && this.user.vbl) return true
      if (this.teams.find(f => !f.finish)) return false
      const finishes = this.teams.map(t => t.finish)
      if (finishes.find(f => f < 1)) return false
      if (finishes.find(f => f > this.teams.length && f !== 99)) return false

      const s = new Set(finishes)
      return s.size === this.teams.length
    },
    warn () {
      return this.teams.find(f => f.finishOG && f.finishOG !== f.finish) && this.pool.tiebreakNotes && this.pool.tiebreakNotes.length > 0
    }
  },
  methods: {
    save () {
      this.loading = true
      this.$emit('finish-manually', this.teams)
    },
    rando () {
      this.teams.forEach((f, i) => { f.finish = (i + 1) })
      this.save()
    }
  },
  watch: {
    open: function (val) {
      if (val) {
        this.teams = this.rankedTeams.map(t => {
          return {
            id: t.id,
            name: t.name,
            slot: t.slot,
            finish: t.summaryx ? t.summary.finish : t.rank === 999 ? null : t.rank,
            finishOG: t.summaryx ? t.summary.finish : t.rank === 999 ? null : t.rank,
            dualWins: t.summaryx ? t.summary.dualWins : t.dualWins,
            dualLosses: t.summaryx ? t.summary.dualLosses : t.dualLosses,
            matchWins: t.summaryx ? t.summary.matchWins : t.matchWins,
            matchLosses: t.summaryx ? t.summary.matchLosses : t.matchLosses,
            setWins: t.summaryx ? t.summary.setWins : t.setWins,
            setLosses: t.summaryx ? t.summary.setLosses : t.setLosses,
            pointsFor: t.summaryx ? t.summary.pointsFor : t.pointsFor,
            pointsAgainst: t.summaryx ? t.summary.pointsAgainst : t.pointsAgainst
          }
        })
      } else {
        this.teams = []
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
>>> input {
  text-align: center;
}
</style>
